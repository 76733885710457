import React from "react";
import api, {TransactionsApi} from "../../api";
import {properties} from "../../properties";
import {HeaderInfo} from "./HeaderInfo";
import {MaterialInfoTabs} from "./MaterialInfoTabs";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const indices = Object.fromEntries(
    months.map((month, index) => [month, (index + 1).toString().padStart(2, '0')])
)

export class MaterialPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {},
            commonality: [],
            mainColumns: [],
            equipment: [],
            hers: [],
            dashboardSummary: [],
            focusSummary: [],
            transactions: [],
            graph: [],
            usageInfo: {
                months: [],
                stats: [],
                count: 0
            },
            headerKeys: [],
            showModal: false,
            structured: [],
            locationsData: [],
            show: false,
            load: false
        }
    }

    setCommonality = commonality => {
        this.setState({commonality})
    }

    setStructured = structured => {
        this.setState({structured})
    }

    getStock(url, operator, material) {
        api.post(`/search/connected?operator=${encodeURIComponent(operator)}&material=${material}&showAll=true`, {url})
            .then(json => {
                this.setState({locationsData: json.data.connected});
            })
            .catch(error => {
                console.log(error);
                this.setState({connectedRows: []});
            });
    }

    getEquipmentTable(operator, material) {
        api.get(`/get/equipment?operator=${encodeURIComponent(operator)}&material=${material}`)
            .then(json => {
                this.setState({equipment: json.data['equipment']})

            })
            .catch(error => console.log(error))
    }

    getStructured = (url, operator, material) => {
        api.post(`/structured/full-data?operator=${encodeURIComponent(operator)}&material=${material}`, {url: url})
            .then(json => {
                this.setStructured(json.data['records'])
            })
            .catch(error => console.log(error))
    }

    getTransactions = (url, operator, material) => {
        TransactionsApi.get(`/inventory/transactions_by_material/${material}?include_pm=true&operator=${operator}`)
            .then(response => {
                this.setState({
                    transactions: response.data['data'],
                })

            })
            .catch(error => console.log(error))
    }

    getSGraphData = (url, operator, material) => {
        api.post(`/item/graph?operator=${encodeURIComponent(operator)}&material=${material}`, {url: url})
            .then(json => {
                this.setState({
                    graph: json.data.graph || []
                })

            })
            .catch(error => console.log(error))
    }

    getSummaryValue = (columns, item) => {
        let summary = []
        for (let key of columns) {
            summary.push({
                key,
                value: item[key] || ''
            })
        }
        return summary
    }

    getUsageStat(operator, material) {
        TransactionsApi.get(`/inventory/material_usage/${material}?type=gi&operator=${operator}`)
            .then(response => {
                const data = response.data['data']
                const years = Object.keys(data).map(Number).sort()
                const stats = []
                let count = 0;
                if (years.length > 0) {
                    for (let year = years[0]; year <= years[years.length - 1]; year++) {
                        const yearStats = {
                            'total': 0,
                            'year': year
                        }
                        months.forEach((month) => {
                            const value = data?.[year]?.[indices[month]] ?? 0
                            yearStats[month] = value
                            yearStats.total += value
                        })
                        count += yearStats.total
                        stats.push(yearStats)
                    }
                }
                stats.sort((a, b) => b.year - a.year)
                this.setState({
                    usageInfo: {
                        months: months,
                        stats: stats,
                        count: count
                    },
                })
            })
            .catch(error => console.log(error))

    }

    getHersRecords = (url, operator, material) => {
        api.post(`/hers/full-data?operator=${encodeURIComponent(operator)}&material=${material}`, {url: url})
            .then(json => {
                this.setState({
                    hers: json.data['records'] || [],
                })

            })
            .catch(error => console.log(error))
    }

    componentDidMount() {
        let operator = new URLSearchParams(window.location.search).get('operator');
        let material = new URLSearchParams(window.location.search).get('material');

        let url = properties.apiUrl;
        if (sessionStorage.getItem("item_url") && sessionStorage.getItem("item_url") !== null) {
            url = sessionStorage.getItem('item_url')
        } else if (localStorage.getItem("item_url") && localStorage.getItem("item_url") !== null) {
            url = localStorage.getItem("item_url")
            sessionStorage.setItem("item_url", url)
        }

        api.post(`/item/full?material=${material}&operator=${encodeURIComponent(operator)}`, {url})
            .then(json => {
                this.setState({
                    item: json.data.item[0],
                    items: json.data.item,
                    load: true,
                    show: true,
                    mainColumns: json.data.mainColumns,
                })

                this.props.onItemUpdate(json.data.item[0].part_number)
                if (!json.data.item[0].type) {
                    json.data.item[0].type = 'material'
                }

                api.get(`/validate_permission?permission=superadmin`)
                    .then(() => {
                        this.setState({show: true})
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        api.get(`/validate_permission?permission=admin`)
                            .then(() => {
                                this.setState({show: true})
                            })
                            .catch(error => {
                                console.log(error.response.data);
                            });
                    });
                this.getStock(url, operator, material)
                this.sendRequest(url, operator, material)
                this.getEquipmentTable(operator, material)
                this.getStructured(url, operator, material)
                this.getUsageStat(operator, material)
                this.getTransactions(url, operator, material)
                this.getSGraphData(url, operator, material)
                this.getHersRecords(url, operator, material)

                let dashboardSummaryKeys = ['Duplicate', 'Obsolete', 'Insurance Spare', 'Slow Moving']
                let focusSummaryKeys = ['BOM Clean-up', 'MM Clean-up', 'No BOMs', 'MIN MAX Optimise']

                let headerKeys = ['operator', 'material', 'description', 'cl_name', 'mat_type', 'mat_group', 'manufacturer', 'part_number', 'item_status']


                this.setState({
                    dashboardSummary: this.getSummaryValue(dashboardSummaryKeys, {}),
                    focusSummary: this.getSummaryValue(focusSummaryKeys, {}),
                    headerKeys
                })
            })
            .catch(() => {
                if (!properties.isDebug) {
                    window.location.href = '/search?search=' + material
                }
            });
    }


    sendRequest = (url, operator, material) => {
        api.post(`/search/report?operator=${encodeURIComponent(operator)}&material=${material}&showAll=${true}`, {url})
            .then(json => {
                this.setCommonality(json.data.filter(v => !(v.operator === operator && v.material === material)))
            })
            .catch(() => {
                this.setCommonality([])
            });
    }

    render() {
        let item = this.state.item;
        if (item && !item.hasOwnProperty('raw'))
            item.raw = item.description;

        return (
            <div>
                <div className='catalog-root center-block'>
                    <HeaderInfo image={this.props.image} item={item} headerColumns={this.state.headerKeys} images={this.props.images}
                                usage={this.state.usageInfo.count || 0}
                                handleModalOpen={() => this.props.openImageModal()} showEdit={this.props.show}/>
                    <MaterialInfoTabs item={item}
                                      structured={this.state.structured}
                                      equipment={this.state.equipment}
                                      dashboardSummary={this.state.dashboardSummary}
                                      transactionsInfo={{
                                          tableData: this.state.transactions,
                                          graphData: this.state.graph
                                      }}
                                      hers={this.state.hers}
                                      updateDocsTable={this.props.getDocumentsList}
                                      documentsList={this.props.documentsList}
                                      focusSummary={this.state.focusSummary}
                                      usageInfo={this.state.usageInfo}
                                      stockData={this.state.items}
                                      commonality={this.state.commonality}
                    />

                </div>
            </div>
        );
    }

}
